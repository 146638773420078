<template>
  <section class="section">
    <div class="container">
      <button class="button right is-primary is-large" :class="{'is-loading': isLoading}" v-on:click="getDev()">Показать данные токена</button>
      <div id="content2" style="overflow: auto;"></div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'Checktools',
  components: {
  },
  props: [
  ],
  data () {
    return {
      devices: null,
      objectsRSA: null,
      objectsGOST: null,
      isLoading: false,
    }
  },
  computed: {
  },
  methods: {
    async getDev(){
      try{
        this.isLoading = true
        var html = '';
        this.devices = await this.cryptoSocket.getDevices({id: 1});
        this.objectsGOST = await this.cryptoSocket.getObjects({ id: 2, reader: this.reader, pass: '1234567890', alg: 'GOST' })
        this.objectsRSA = await this.cryptoSocket.getObjects({ id: 3, reader: this.reader, pass: '1234567890', alg: 'RSA' })

        for(var i = 0; i < this.
            devices.devices.length; i++){

          html += '<br>';
          html += '<br>';
          html += '<h1>Алгоритм: '+this.devices.devices[i].algorithm+'</h1>';
          html += '<h1>Название: '+this.devices.devices[i].name+'</h1>';
          html += '<h1>Reader: '+this.devices.devices[i].reader+'</h1>';
          html += '<br>';
        }

        html += 'Objects GOST';
        let data1 = JSON.parse(this.objectsGOST.objects);
        console.log(data1);
        /*html += '<h1><b>Reader:</b> '+data1.reader+'</h1>';
        html += '<h1><b>Name:</b> '+data1.objects[0].name+'</h1>';
        html += '<h1><b>KeyAlgID:</b> '+data1.objects[0].name+'</h1>';
        if(data1.objects[0].cert_b64)
          html += '<h1><b>CertB64:</b> '+data1.objects[0].cert_b64+'</h1>';
        if(data1.objects[1].pubkey_b64)
          html += '<h1><b>PubKeyB64:</b> '+data1.objects[1].pubkey_b64+'</h1>';*/
        html += '<h1>Objects: '+this.objectsGOST.objects+'</h1>';

        html += '<br>';
        html += '<br>';

        html += 'Objects RSA';
        let data = JSON.parse(this.objectsRSA.objects);
        console.log(data);
        html += '<h1>Objects: '+this.objectsRSA.objects+'</h1>';
        /*html += '<h1><b>Reader:</b> '+data.reader+'</h1>';
        html += '<h1><b>Name:</b> '+data.objects[0].name+'</h1>';
        html += '<h1><b>KeyAlgID:</b> '+data.objects[0].name+'</h1>';
        if(data.objects[0].cert_b64)
          html += '<h1><b>CertB64:</b> '+data.objects[0].cert_b64+'</h1>';
        if(data.objects[1].pubkey_b64)
          html += '<h1><b>PubKeyB64:</b> '+data.objects[1].pubkey_b64+'</h1>';*/


        document.getElementById('content2').innerHTML = html;
        this.isLoading = false
      }catch (e){
        console.log(e);
      }
    }
  },
  mounted () {
    this.startSocket();
  }
}
</script>
<style>
.bnsk{
  display: block;
}
</style>
